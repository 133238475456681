import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDividerModule} from "@angular/material/divider";
import {MatInputModule} from "@angular/material/input";


@Component({
  selector: 'kodl-json-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule, FormsModule, MatInputModule, ReactiveFormsModule, MatDividerModule],
  template: `
    <form>
      <div class="flex items-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">JSON</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>

      <mat-divider class="text-border"></mat-divider>

      <div mat-dialog-content class="flex flex-col">
   <pre>
     {{data | json}}
   </pre>

      </div>

      <div mat-dialog-actions>
        <button color="primary" mat-dialog-close>OK</button>
      </div>
    </form>
  `
})
export class JsonModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<JsonModalComponent>) {
  }
  ngOnInit() {
  }
}
