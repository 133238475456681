import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {Router, RouterModule} from '@angular/router';
import {ComponentsModule} from '../components.module';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../../environments/environment";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {JsonModalComponent} from "../json-modal/json-modal.component";
import {MatInputModule} from "@angular/material/input";
import {Role} from "../../../../@backend";

interface ToolkitConfig {
  tokenSecret: string;
}
@Component({
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule, MatRippleModule, RouterModule, ComponentsModule, MatDialogModule, MatSelectModule, MatTooltipModule, FormsModule, ClipboardModule, ReactiveFormsModule],
  selector: 'app-toolkit-modal',
  template: `
    <div class="w-full overscroll-y-contain overflow-y-auto p-4">
        <div class="flex flex-col gap-3 w-full">
          <div class="text-2xl font-bold text-secondary px-2 mb-2">App ToolKit</div>

          <div class="flex flex-row gap-4 items-center">

              <button mat-flat-button color="primary" matTooltip="Přihlašovací token" [cdkCopyToClipboard]="authService.currentUser.token">
                <mat-icon svgIcon="mat:vpn_key"></mat-icon>
                Token
              </button>

              <button mat-flat-button color="primary" matTooltip="Explorer" (click)="openURL(environment.baseApiUrl + '/explorer')">
                <mat-icon svgIcon="mat:api"></mat-icon>
                Explorer
              </button>

          </div>

          <div class="flex flex-col gap-4">
            <div class="flex text-xl flex-row border-b border-gray">User</div>
            <div class="flex flex-col gap-2">
              <div><span class="font-bold">Přihlášený jako:</span> {{authService.currentUser.firstname}} {{authService.currentUser.lastname}} ({{authService.currentUser.email}})</div>
<!--              <div class="flex flex-row items-center">-->
<!--                <div><span class="font-bold">Role:</span></div>-->
<!--                @for(role of authService.currentUser.roles; track role) {-->
<!--                  <div class="bg-gray-200 px-2 m-1 rounded-md">{{role.name}}</div>-->
<!--                }-->
<!--              </div>-->

              <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
                <mat-label>Role</mat-label>
                <mat-select [(ngModel)]="authService.currentUser.roles" [multiple]="true">
                  @for(role of roles; track role) {
                    <mat-option [value]="role">{{role.name}}</mat-option>
                  }
                </mat-select>
                <mat-icon matPrefix svgIcon="mat:group"></mat-icon>
              </mat-form-field>

              <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
                <mat-label>Expirace</mat-label>
                <input matInput [(ngModel)]="authService.currentUser.exp" />
              </mat-form-field>

              <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
                <mat-label>Token secret</mat-label>
                <input matInput [(ngModel)]="toolkitConfig.tokenSecret" />
              </mat-form-field>

              <div class="flex flex-row gap-2">
                <button mat-flat-button color="primary" (click)="saveUser()" [disabled]="toolkitConfig.tokenSecret === undefined">
                  Uložit
                </button>

                <button mat-flat-button color="primary" (click)="openJsonDialog(authService.currentUser)">
                  Zobrazit JSON uživatele
                </button>
              </div>
            </div>
          </div>





          <div class="flex flex-col gap-4">
            <div class="flex text-xl flex-row border-b border-gray">Environment</div>
            <div class="flex flex-col gap-2">

            </div>
          </div>


        </div>
    </div>
  `
})
export class AppToolkitModalComponent implements OnInit {

  protected readonly environment = environment;
  loading: boolean = true;
  toolkitConfig: ToolkitConfig = {} as ToolkitConfig;
  roles: Role[] = [{name: 'Guest'}, {name: 'User'}, {name: 'Admin'}, {name: 'Translator'}, {name: 'Photographer'}, {name: 'Collaborator'}, {name: 'Authors'}, {name: 'Limiter'}];

  constructor(private dialogRef: MatDialogRef<AppToolkitModalComponent>, private dialog: MatDialog, public authService: AuthenticationService ) {
    const toolkitConfig = localStorage.getItem('apptoolkit.config');
    if(toolkitConfig) {
      try {
        this.toolkitConfig = JSON.parse(localStorage.getItem('apptoolkit.config') || '{}') as ToolkitConfig;
      } catch (error) {
        console.error('Error parsing localStorage data', error);
        this.clear();
      }
    }
  }

  ngOnInit(): void {
  }

  closeModal(redirectLink: string | null = null): void {
    if(redirectLink) {
      this.dialogRef.close(redirectLink);
    }else {
      this.dialogRef.close(true);
    }
  }

  clear() {
    // localStorage.removeItem('search.request');
    // localStorage.removeItem('search.result');
  }

  openURL(url: string): void {
    window.open(url, '_blank');
  }

  openJsonDialog(data: any) {
    this.dialogRef.close();
    this.dialog.open(JsonModalComponent, {
      data
    });
  }

  expire(exp: number) {
    const date = new Date(exp * 1000);
    return date.toLocaleString();
  }

  saveUser() {
    // this.authService.currentUser.exp = Math.round(new Date().getTime() / 1000);
    // localStorage.setItem('apptoolkit.config', JSON.stringify(this.toolkitConfig));
  }
}
